@value variables: '../../../../styles/variables.module.css';
@value primary, primaryOpaque, info, font-small, font-large, vertical-padding, horizontal-padding from variables;

.container {
  padding: 16px 20px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hidden {
  position: fixed;
  visibility: hidden;
}

.imageContainer {
  position: relative;
  margin-top: 75px;
}

.image {
  max-width: 100%;
  border-radius: 28px 28px 0 0;
}

.tapWrapper {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: -webkit-center;
}

.tapButtonWrapper {
  position: absolute;
  top: 0;
}

.tapButton {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: -webkit-center;
  border: 5px solid #fecc0b;
  box-shadow: 0 0 5px 5px rgba(254, 204, 11, 0.5);
  background: #fecc0b;
  animation: tapMe 1s ease infinite;
}

.tapButton img {
  width: 100px;
  height: 100px;
}

.tapButtonText {
  color: #fff;
  text-align: center;
  font-family: Inerta;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
}

@keyframes tapMe {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(120%);
  }
  100% {
    transform: scale(100%);
  }
}
