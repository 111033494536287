@value variables: '../../styles/variables.module.css';
@value primary, light from variables;

.title {
  display: inline-block;
  padding: 0 35px;
  font-size: 34px;
  line-height: 56px;
  color: light;
  font-weight: bold;
  border: 1px solid rgba(234, 188, 75, 1);
  border-radius: 28px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 4vw;
  padding: 30px 0;
}

.disabledLink {
  pointer-events: none;
}

.additionalBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #414181, transparent );
  color: white;
  padding: 15px;
  border-radius: 20px;
}

.marginTop {
  margin-top: 30px;
}

.paddingBottom {
  padding-bottom: 60px;
}

@media (max-width: 370px) {
  .list {
    grid-template-columns: 250px;
    justify-content: center;
    gap: 35px 4vw;
  }
}

@media (min-width: 500px) {
  .list {
    gap: 35px 4vw;
  }
}
