@value variables: '../../../../styles/variables.module.css';
@value primary, primaryOpaque, info, light, font-small, font-extraSmall, font-regular from variables;

.wrapper {
  position: relative;
}

.container {
  position: relative;
}

.blocked {
  opacity: 25%;
}

.image {
  width: 100%;
  min-height: 160px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.textBlock {
  padding: 12px 12px 16px 12px;
  border-radius: 0 0 12px 12px;
  background-color: light;
}

.bio {
  display: flex;
  justify-content: space-between;
  align-items: start;
  row-gap: 20px;
}

.name {
  font-size: font-small;
  line-height: 16px;
  font-weight: bold;
  color: primary;
  text-transform: capitalize;
}

.number {
  display: inline-block;
  width: 26px;
  height: 26px;
  font-size: 9px;
  line-height: 26px;
  font-weight: bold;
  color: rgba(0, 108, 183, 1);
  text-align: center;
  vertical-align: middle;
  background-image: url('../../../../assets/images/tshirt.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.position {
  padding-top: 5px;
  font-size: font-extraSmall;
  line-height: 17px;
  color: primaryOpaque;
}

.animatedCardWrapper {
  position: relative;
  max-width: 900px;
  flex: 1;
  aspect-ratio: 1 / 1;
  padding-top: 12px;
  border-radius: 15px 15px 0 0;
  background-color: #D3E058;
}

.cardTypeWrapper {
  display: flex;
  margin: 0 10px;
  justify-content: space-between;
}

.cardTypeWrapper img {
  width: 100%;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.logoWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  padding: 10px;
  background-color: white;
  text-align-last: center;
}

.players {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  aspect-ratio: 1 / 1;
}

.mainPlayer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 95%;
  left: 0;
  background-size: cover;
  z-index: 1;
  animation: mainPlayer 5s ease-in-out infinite;
}

.mainPlayer.notAnimated {
  animation: none;
}

.leftPlayer {
  position: absolute;
  width: 63%;
  height: 80%;
  left: 0;
  bottom: 0;
  background-position-x: 140%;
  background-size: cover;
  animation: moveLeftPlayer 5s ease-in-out infinite;
}

.leftPlayer.notAnimated {
  animation: none;
}

.rightPlayer {
  position: absolute;
  width: 63%;
  height: 80%;
  right: 0;
  bottom: 0;
  background-position-x: -40%;
  background-size: cover;
  animation: moveRightPlayer 5s ease-in-out infinite;
}

.rightPlayer.notAnimated {
  animation: none;
}

.topText {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
  text-align: center;
}

@media (max-width: 370px), (min-width: 500px) {
  .name {
    font-size: font-regular;
    line-height: 20px;
  }

  .textBlock {
    padding: 16px 16px 24px 16px;
  }

  .number {
    width: 34px;
    height: 34px;
    font-size: font-extraSmall;
    line-height: 34px;
  }

  .position {
    font-size: font-small;
    line-height: 24px;
  }
}
