.hidden {
  position: fixed;
  visibility: hidden;
}

.wrapper {
  margin-bottom: -26px;
}

.header {
  position: relative;
  text-align: center;
  height: 65px;
  background-color: #131339;
  margin-bottom: 11px;
}

.confetti svg {
  position: absolute;
  animation: confetti 1s ease;
}

.small {
  width: 10px;
  height: 10px;
}

.medium {
  width: 13px;
  height: 13px;
}

.big {
  width: 21px;
  height: 21px;
}

.yellowSmallCircle {
  composes: small;
  top: 40%;
  left: 1%;
}

.yellowMediumCircle {
  composes: medium;
  top: -5%;
  left: 80%;
}

.yellowBigCircle {
  composes: big;
  top: -10%;
  left: 15%;
}

.blueMediumCircle {
  composes: medium;
  top: 50%;
  left: 87%;
}

.blueMediumCircle circle {
  fill: #6c8aff;
}

.blueSmallPlus {
  composes: small;
  top: 70%;
  left: 75%;
}

.blueSmallPlus path {
  stroke: #6c8aff;
}

.blueBigPlus {
  composes: big;
  top: 50%;
  left: 20%;
}

.blueBigPlus path {
  stroke: #6c8aff;
}

.yellowBigPlus {
  composes: big;
  top: 15%;
  left: 95%;
}

.yellowBigPlus path {
  stroke: #fecc0b;
}

.countWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.count {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  font-family: Inerta, sans-serif;
  font-size: 48px;
  font-weight: 700;
  align-content: center;
  color: #ffffff;
  opacity: 0.4;
}

.progressBar {
  composes: count;
  color: transparent;
  background-clip: text !important;
  opacity: 1;
  transition: all 1s ease-in-out;
}

@keyframes confetti {
  0% {
    opacity: 0;
    top: 100%;
    transform: rotate(50deg);
  }
}

@keyframes count {
  0% {
    opacity: 0;
    transform: translate(-50%, 70%) scale(0%);
  }
}

@keyframes hideCount {
  0% {
    opacity: 1;
  }
}
