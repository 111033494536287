@value variables: "../../styles/variables.module.css";
@value horizontal-padding, vertical-padding from variables;

.container {
  height: 100vh;
  padding: vertical-padding horizontal-padding;
  box-sizing: border-box;
  overflow-y: auto;
}

.blurContainer {
  composes: container;
  filter: blur(5px);
  position: fixed;
  overflow: hidden;
}
