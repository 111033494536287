@value variables: '../../styles/variables.module.css';
@value primary, primaryOpaque, info, font-small, font-large from variables;

.spinnerContainer {
  width: 100vw;
  height: 100vh;
}

.emptyBlock {
  width: 1px;
  height: 35px;
}

.playerWrapper {
  padding: 80px 20px 0 20px;
  background: linear-gradient(180deg, #5656a5 0%, rgba(19, 19, 57, 0) 100%);
}

.playerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  color: #ffffff;
}

.number {
  display: inline-block;
  width: 62px;
  height: 62px;
  font-size: 22px;
  line-height: 62px;
  font-weight: bold;
  color: rgba(0, 108, 183, 1);
  text-align: center;
  vertical-align: middle;
  background-image: url('../../assets/images/tshirt.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.position {
  padding-top: 10px;
  font-size: font-large;
  color: rgba(255, 255, 255, 0.5);
  line-height: 24px;
}

.playerInfoDetails {
  width: 100%;
  border-collapse: collapse;
}

.playerInfoDetails td {
  border-bottom: 1px solid #669;
  padding-top: 10px;
  vertical-align: top;
}

.playerInfoDetails td:last-child {
  text-align: end;
}

.playerInfoDetails td {
  color: #fff;
  font-family: Inerta;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
