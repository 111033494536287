@values variables: '../../../../styles/variables.module.css';
@values blueSky from variables;

.container > div {
  position: relative;
  min-width: 48px;
  width: fit-content;
}

.scoreWrapper {
  position: relative;
  min-width: 48px;
  padding-left: 10px;
  width: fit-content;
  transition: 1s ease;
}

.container img {
  position: absolute;
  top: 12px;
  left: -24px;
  transform: translate(-50%, -50%);
}

.text {
  display: inline-block;
  font-size: 18px;
  color: #fecc0b;
  font-weight: bold;
  text-wrap: nowrap;
}

.container .animatedIcon {
  right: 4%;
  border-radius: 50%;
  background-color: rgba(254, 204, 11, 0.35);
  text-align: -webkit-center;
  animation: changeScoreIconAnimation 1000ms ease infinite;
}

.superCaseText {
  composes: text;
  font-variant-position: super;
}

.animatedText {
  composes: text;
  position: absolute;
  left: 10px;
  transform: scale(120%);
}

.superCaseAnimatedText {
  composes: animatedText;
  font-variant-position: super;
}

@keyframes changeScoreIconAnimation {
  50% {
    width: 48px;
    height: 48px;
    box-shadow: 0 0 0 8px rgba(254, 204, 11, 0.35);
  }
  80% {
    width: 55px;
    height: 55px;
  }
  100% {
    width: 48px;
    height: 48px;
    box-shadow: 0 0 0 2px rgba(254, 204, 11, 0);
  }
}
