.imageWrapper {
  position: relative;
  min-width: 110px;
  min-height: 130px;
}

.imageWrapper img {
  position: absolute;
  top: 16px;
  left: -6px;
  width: 120px;
}
