@value variables: "../../styles/variables.module.css";
@value primary, light, font-regular from variables;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 28px;
  border-radius: 12px;
  background-color: #fecc0b;
  border: 0;
}

.container:disabled {
  background-color: #D8D8D8;
}

.text {
  color: #131339;
  font-family: Inerta;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.iconWrapper {
  padding-left: 15px;
}
