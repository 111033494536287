.WBBannerWrapper {
  position: relative;
  margin-top: 32px;
  border-radius: 12px;
  background: linear-gradient(270deg, #07bbb2 0%, #131339 100%);
}

.WBBannerWrapper img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.bannerText {
  width: 60%;
  position: absolute;
  top: 18px;
  left: 18px;
  font-family: Inerta;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bannerText a {
  color: #fff;
  text-decoration-line: underline;
}

.bannerButton {
  position: absolute;
  top: 90px;
  left: 18px;
  display: flex;
  padding: 18px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #d3e058;
}

.bannerButton span {
  color: #111132;
  font-family: Inerta;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.textUnderBanner {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: Inerta;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.textUnderBanner a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration-line: underline;
}
