.imageWrapper {
  position: relative;
  min-width: 110px;
  min-height: 140px;
}

.imageWrapper img {
  position: absolute;
  top: 5px;
  width: 130px;
}
