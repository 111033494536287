.wrapper {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  border-radius: 12px;
  border: 1px solid #fecc0b;
}

.count {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.count svg {
  min-width: 40px;
  min-height: 40px;
}

.countText {
  color: #ffffff;
  font-family: Inerta;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.countText span {
  font-variant-position: super;
}

.countText img {
  width: 40px;
}

.description {
  composes: countText;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.6;
}
