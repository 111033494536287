@value variables: "../../styles/variables.module.css";
@value primary, light, primaryOpaque, font-regular, layout, lightOpaque from variables;

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.24);
}

.content {
  position: absolute;
  width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  padding: 25px;
  box-sizing: border-box;
  outline: 0;
  background-color: light;
}

.container {
  text-align: center;
}

.header {
  display: flex;
  justify-content: end;
}

.message {
  padding-top: 8px;
  font-size: font-regular;
  line-height: 24px;
  color: lightOpaque;
}

.title {
  color: layout;
}

.imageBlock {
  padding: 40px 0;
}

.image {
  max-width: 90%;
  margin: auto;
}

@media (max-width: 350px) {
  .content {
    width: 95%;
  }
}
