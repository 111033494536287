@value variables: "../../styles/variables.module.css";
@value primary, light, primaryOpaque, font-regular, layout, lightOpaque from variables;

.modalContent {
  padding: 0 16px;
}

.message {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: layout;
  text-transform: uppercase;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.24);
}

.content {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  padding: 16px;
  box-sizing: border-box;
  background-color: light;
  outline: 0;
}

.answersWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;
}

.option {
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.25);
  padding: 15px;
  border-radius: 20px;
  transition: background-color 0.5s;
}

.option.selected {
  background-color: #131339;
  color: white;
}

.container {
  text-align: center;
}

.header {
  display: flex;
  justify-content: end;
}

.bottomText {
  margin-top: 10px;
}
