.wrapper {
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(#5656a5, #131339 90%);
  color: #ffffff;
}

.collectorWrapper {
  background: linear-gradient(#5656a5, #131339 355px);
}

.wrapper p span {
  font-family: Inerta;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.6;
}

.wrapper h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.wrapper svg path {
  fill: #fecc0b;
}

.type {
  display: flex;
  margin-bottom: 24px;
}

.imageWrapper {
  position: relative;
  min-width: 120px;
  min-height: 130px;
}

.imageWrapper img {
  position: absolute;
  top: 16px;
  width: 120px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin: 20px 20px 0 0;
}

.gotCollection {
  color: #fecc0b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.achievementInfo {
  display: flex;
  min-width: 278px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  margin: 0 32px 24px 32px;
}

.achievementInfo:last-child {
  margin-bottom: 0;
}

.whiteText {
  color: #fff;
  opacity: 1;
}

.whiteText a {
  color: #fff;
  text-decoration-line: underline;
}

.mainTextWrapper {
  display: flex;
  align-items: center;
  gap: 9px;
}

.buttonWrapper {
  padding: 0 20px;
}

.playerText {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  opacity: 0.6;
}
