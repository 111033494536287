@value variables: "../../../../../styles/variables.module.css";
@value layout, light from variables;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.mask {
  position: absolute;
  box-shadow: 0 0 20px 100vmax rgba(19, 19, 57, 0.5);
  border-radius: 5px;
  border-width: 5px;
  border-style: dashed solid;
  border-color: light;
}

.videoContent {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: layout;
}

.buttonContainer {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
