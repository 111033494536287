@values variables: '../../../../styles/variables.module.css';
@values secondary, font-extraSmall from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-size: font-extraSmall;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.container path {
  fill: rgba(255, 255, 255);
}

.active {
  composes: container;
  color: secondary;
}

.active path {
  fill: secondary;
  fill-opacity: 1;
}

.active .text {
  color: secondary;
}
