@value variables: "../../styles/variables.module.css";
@value light, layout, font-extraLarge from variables;

.backgroundWrapper {
  background: radial-gradient(130.9% 50% at 50% 50%, #424275 0%, #131339 100%);
}

.title {
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
  color: light;
  font-weight: bold;
}

.grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 25px;
  box-sizing: border-box;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.image {
  position: relative;
  left: 25px;
}

.message {
  padding-top: 32px;
  font-size: font-extraLarge;
  font-weight: bold;
  color: light;
}

.icon {
  display: block;
}

.icon path {
  fill: layout;
}
