.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blurContainer {
  composes: container;
  filter: blur(5px);
}
