@value variables: "../../styles/variables.module.css";
@value primary, light, layout, vertical-padding, horizontal-padding from variables;

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: linear-gradient(180deg, #131339 0%, #424275 100%);
}

.headerBlock {
  padding: calc(vertical-padding + 35px) horizontal-padding 0 horizontal-padding;
}

.title {
  padding: 30px 0 40px 0;
  text-align: center;
}

.menuBorder {
  height: 1px;
  opacity: 0.1;
  background-color: light;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px horizontal-padding;
}

.icon path {
  color: light;
}

.arrow {
  color: light;
}

.menuItemContent {
  display: flex;
  align-items: center;
}

.menuItemText {
  display: inline-block;
  padding-left: 12px;
  font-size: font-regular;
  color: #ffffff;
}

.supportIcon path {
  fill: layout;
}

.buttonBlock {
  padding: 0 horizontal-padding 70px horizontal-padding;
}
