.container {
  background: #131339;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 120px;
}

.loaderWrapper {
  min-width: 100vw;
  min-height: 100vh;
  align-content: center;
}
