.buttonWrapper {
  margin-top: 20px;
  padding: 0 20px;
}

.button {
  background: transparent;
  border: 1px solid #FECC0B;
}

.button svg path {
  fill-opacity: 1;
}

.text {
  color: #FECC0B;
}
