@value variables: "../../styles/variables.module.css";
@value lightOpaque, layout, font-regular from variables;

.background {
  background: linear-gradient(180deg, #131339 0%, #424275 100%);
}

.main {
  padding: 30px 0 25px 0;
}

.subtitle {
  padding: 25px 0 12px 0;
}

.stub {
  width: 100%;
  height: 35px;
}

.paragraph {
  font-size: font-regular;
  color: lightOpaque;
  line-height: 24px;
  word-wrap: normal;
}

.paragraph a {
  text-decoration: underline;
  color: white;
}

.paragraph-gap {
  padding-bottom: 12px;
}

.list {
  margin: 0;
  padding-inline-start: 30px;
}

.link {
  color: inherit;
  text-decoration: underline;
}

.playerCard {
  width: 85px;
  vertical-align: bottom;
}
