@value variables: "../../styles/variables.module.css";
@value primary, light, primaryOpaque, font-regular, layout, lightOpaque from variables;

.loaderWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  inset: 0;
  outline: 0;
}

.loaderContent {
  outline: 0;
  background-color: #131339;
}

.loaderWrapper > div > div {
  height: 100vh;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.24);
}

.content {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  padding: 16px;
  box-sizing: border-box;
  outline: 0;
}

.lightTheme {
  background-color: light;
}

.darkTheme {
  background: linear-gradient(180deg, #131339 0%, #424275 100%);
}

.container {
  text-align: center;
}

.header {
  display: flex;
  justify-content: end;
}

.message {
  padding-top: 8px;
  font-size: font-regular;
  line-height: 24px;
  color: layout;
}

.title {
  color: layout !important;
}

.errorTitle {
  color: light;
}

.errorMessage {
  color: lightOpaque;
}

.message a {
  color: inherit;
  text-decoration: underline;
}

.imageBlock {
  padding: 40px 0;
}

.image {
  max-width: 100%;
  margin: auto;
}

.buttonBlock {
  padding: 0 15px 25px 15px;
  box-sizing: border-box;
}

.errorButton {
  padding: 12px 28px;
  background-color: layout !important;
}

.errorButtonText {
  color: light !important;
}

.errorClose path {
  stroke: lightOpaque;
}


@media (max-width: 350px) {
  .content {
    width: 100%;
  }
}
