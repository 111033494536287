@value variables: '../../styles/variables.module.css';
@value primary from variables;

.title {
  color: #ffffff;
  font-weight: bold;
}

.h1 {
  font-size: 40px;
  line-height: 56px;
}

.h2 {
  font-size: 32px;
  line-height: 38px;
}

.h3 {
  font-size: 20px;
  line-height: 28px;
}
