.titleContainer {
  color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.cardCollected {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cardsTotalIconWrapper {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
}

.cardCollectedBold {
  color: #ffffff;
  font-family: Inerta;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardCollectedRegular {
  composes: cardCollectedBold;
  font-size: 20px;
  opacity: 0.4;
  margin-bottom: -20px;
}

.text {
  composes: cardCollectedBold;
  font-size: 20px;
  line-height: 28px;
}
