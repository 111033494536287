@value variables: '../../../../styles/variables.module.css';
@value primary, primaryOpaque, info, font-small, font-large from variables;

.imageContainer {
  position: relative;
  padding-top: 25px;
  display: flex;
}

.image {
  max-width: 100%;
  border-radius: 28px 28px 0 0;
}

.tapWrapper {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: -webkit-center;
  z-index: 1;
}

.tapWrapper.tapProcess {
  bottom: -30px;
}

.tapButtonWrapper {
  position: absolute;
  top: 0;
}

.tapButton {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: -webkit-center;
  border: 5px solid #fecc0b;
  box-shadow: 0 0 10px 5px rgba(254, 204, 11, 0.8);
  background: #fecc0b;
  animation: tapMe 1s ease infinite;
}

.tapButton img {
  width: 100px;
  height: 100px;
}

.tapButtonText {
  color: #fff;
  text-align: center;
  font-family: Inerta;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
}

.animatedCardWrapper {
  position: relative;
  min-width: 300px;
  max-width: 900px;
  flex: 1;
  background: linear-gradient(#E1E4EC, #E1E4EC);
  aspect-ratio: 1 / 1;
  padding-top: 30px;
  border-radius: 24px 24px 0 0;
}

.cardTypeWrapper {
  display: flex;
  margin: 0 20px;
  justify-content: space-between;
}

.cardType {
  width: 70%;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  padding: 10px;
  background-color: white;
}

.typeText {
  font-family: Inerta;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  align-content: center;
  margin-left: 10px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.logoWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  padding: 10px;
  background-color: white;
  text-align-last: center;
}

.players {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  aspect-ratio: 1 / 1;
}

.mainPlayer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 95%;
  left: 0;
  background-size: cover;
  z-index: 1;
  animation: mainPlayer 5s ease-in-out infinite;
}

.mainPlayer.notAnimated {
  animation: none;
}

.leftPlayer {
  position: absolute;
  width: 63%;
  height: 80%;
  left: 0;
  bottom: 0;
  background-position-x: 140%;
  background-size: cover;
  animation: moveLeftPlayer 5s ease-in-out infinite;
}

.leftPlayer.notAnimated {
  animation: none;
}

.rightPlayer {
  position: absolute;
  width: 63%;
  height: 80%;
  right: 0;
  bottom: 0;
  background-position-x: -40%;
  background-size: cover;
  animation: moveRightPlayer 5s ease-in-out infinite;
}

.rightPlayer.notAnimated {
  animation: none;
}

@keyframes tapMe {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(120%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes mainPlayer {
  0% {
    left: -4%;
  }
  50% {
    left: 4%;
  }
  100% {
    left: -4%;
  }
}

@keyframes moveLeftPlayer {
  0% {
    background-position-x: 130%;
  }
  50% {
    background-position-x: 150%;
  }
  100% {
    background-position-x: 130%;
  }
}

@keyframes moveRightPlayer {
  0% {
    background-position-x: -50%;
  }
  50% {
    background-position-x: -30%;
  }
  100% {
    background-position-x: -50%;
  }
}
