@value variables: '../../styles/variables.module.css';
@value primary, font-extraSmall from variables;

.wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 69px;
  padding: 0 42px;
  background-color: #131339;
  border-radius: 36px 36px 0 0;
  z-index: 2;
}

.qrcodeBlock {
  position: relative;
  top: -24px;
  left: 20px;
}

.background {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  box-shadow: 0 0 99px 58px #8c8cdd;
}
