@import-normalize;
@import './fonts.css';

body,
h1,
h2,
h3,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

a img {
  outline: none;
}

body {
  font-family: 'Inerta';
  background: #131339;
}

img {
  vertical-align: top;
}
