@font-face {
  font-family: 'Inerta';
  font-weight: 400;
  src: url('../assets/fonts/Inerta-Regular.ttf');
}

@font-face {
  font-family: 'Inerta';
  font-weight: bold;
  src: url('../assets/fonts/Inerta-Bold.ttf');
}
