@value variables: '../../../../../styles/variables.module.css';
@value secondary from variables;

.container {
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background:
    linear-gradient(#fecc0b, #fecc0b) padding-box,
    linear-gradient(to top, #feab0b, #ffffff) border-box;
  border: 3px solid transparent;
  box-shadow:
    0 0 3px 0 rgba(255, 190, 24, 0.82),
    0 3px 10px 0 rgba(255, 122, 0, 0.42);
}
