.wrapper {
  position: relative;
  display: flex;
  height: 170px;
  background: linear-gradient(to left, #87DF16, rgba(255, 153, 0, 0));
  color: #ffffff;
  justify-content: space-between;
  border-radius: 12px;
  margin-top: 20px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 15px;
  gap: 15px
}

.text {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.buttonWrapper {
  padding: 15px;
  border-radius:  60px;
}

.buttonText {
  font-size: 20px;
}

.wrapper img {
  position: absolute;
  height: 100%;
  right: 0;
}

.wrapper svg {
  position: absolute;
  right: 0;
}
