.imageWrapper {
  position: relative;
  min-width: 110px;
  min-height: 130px;
}

.imageWrapper img {
  position: absolute;
  top: 25px;
  left: 10px;
  width: 90px;
}
