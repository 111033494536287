.wrapper {
  min-width: 100vw;
  min-height: 100vh;
  background: rgb(25 25 52);
}

.lottie {
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  min-width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
